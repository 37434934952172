import React, { useEffect, useState } from "react";
// import NavBar from "./NavBar";
// import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { useTheme } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import ViewInArTwoToneIcon from "@mui/icons-material/ViewInArTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CardHeader from "@mui/material/CardHeader";

function convertToCSV(data) {
  const header = [
    "Count",
    "Duration",
    "Instance Type",
    "OS",
    "Payment Option",
    "Rate",
    "Region",
    "Upfront Price",
    "On Demand Price",
    "Savings Percentage",
    "Normalized Units",
  ];

  const csvData = data.map((row) => [
    row.count,
    row.duration
      ? `${(Math.round(parseFloat(row.duration) * 10) / 10).toFixed(1)} months`
      : "-",
    row["instance type"],
    row.os,
    row["payment option"],
    row.rate ? `$${row.rate.toFixed(5)}` : "-",
    row.region,
    row["upfront price"] ? `$${row["upfront price"].toFixed(2)}` : "-",
    row["od_rate"] ? `$${row["od_rate"].toFixed(2)}` : "-",
    row.adjusted_savings_percentage
      ? `${row.adjusted_savings_percentage.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}%`
      : "-",
    normalizationFactors[row["instance type"].split(".")[1]],
  ]);

  const csvArray = [header, ...csvData];

  return csvArray.map((row) => row.join(",")).join("\n");
}

function exportCSV(data) {
  const csvContent = convertToCSV(data);
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "marketplace_data.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const normalizationFactors = {
  nano: 0.25,
  micro: 0.5,
  small: 1,
  medium: 2,
  large: 4,
  xlarge: 8,
  "2xlarge": 16,
  "3xlarge": 24,
  "4xlarge": 32,
  "6xlarge": 48,
  "8xlarge": 64,
  "9xlarge": 72,
  "10xlarge": 80,
  "12xlarge": 96,
  "16xlarge": 128,
  "18xlarge": 144,
  "24xlarge": 192,
  "32xlarge": 256,
  "56xlarge": 448,
  "112xlarge": 896,
};

const regions = [
  { region: "us-east-1" },
  { region: "us-east-2" },
  { region: "us-west-1" },
  { region: "us-west-2" },
  { region: "af-south-1" },
  { region: "ap-east-1" },
  { region: "ap-south-1" },
  { region: "ap-northeast-3" },
  { region: "ap-northeast-2" },
  { region: "ap-southeast-1" },
  { region: "ap-southeast-2" },
  { region: "ap-northeast-1" },
  { region: "ca-central-1" },
  // { region: 'cn-north-1' },
  // { region: 'cn-northwest-1' },
  { region: "eu-central-1" },
  { region: "eu-west-1" },
  { region: "eu-west-2" },
  { region: "eu-south-1" },
  { region: "eu-west-3" },
  { region: "eu-north-1" },
  { region: "me-south-1" },
  { region: "sa-east-1" },
];

const os_type = [{ os: "linux" }, { os: "windows" }];

const initialState = [
  { ec2_type: "m5.large" },
  { ec2_type: "c5.large" },
  { ec2_type: "r5.large" },
  { ec2_type: "t3.large" },
  { ec2_type: "m5.xlarge" },
  { ec2_type: "c5.xlarge" },
  { ec2_type: "r5.xlarge" },
  { ec2_type: "t3.xlarge" },
];

const family_initialState = [
  { ec2_type: "m5" },
  { ec2_type: "c5" },
  { ec2_type: "r5" },
  { ec2_type: "t3" },
];

function EC2MarketPlace({}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [marketplace, set_marketplace] = useState({});
  const [marketLoading, setMarketLoading] = useState(false);
  const [instances, set_instances] = useState(initialState);
  const [instance_family, set_instance_family] = useState(family_initialState);
  const [region, set_region] = useState("");
  const [os, set_os] = useState("");
  const [formData, setFormData] = useState({
    region: "",
    ec2_type: "",
    os_type: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  async function fetchMarket(e) {
    const region = e.target.elements.region.value;
    const ec2_type = e.target.elements.ec2_type.value;
    const os_type = e.target.elements.os_type.value;
    setMarketLoading(true);
    e.preventDefault();
    const apiData = await fetch(
      `https://wzstkt5ta4.execute-api.us-east-1.amazonaws.com/dev-v2/multi-all-family-marketplace/${ec2_type}/${region}/${os_type}`
    ).then((res) => res.json());
    // console.log(`marketplace data ${apiData}`)
    if (region) {
      set_marketplace({
        market: apiData,
      });
      setOpen(true);
      // console.log(marketplace)
      setMarketLoading(false);
    } else {
      set_marketplace({
        error: "problem with spot fetch",
      });
    }
  }

  async function onChangeHandle(value) {
    const region = value;
    // console.log("calling AWS API!")
    const apiData = await fetch(
      `https://u27tg888eb.execute-api.us-east-1.amazonaws.com/dev/instances/${region}/`
    ).then((res) => res.json());
    if (region) {
      set_instances(apiData);
    } else {
      set_instances({
        error: "Please select region",
      });
    }
  }
  // const theme = useTheme();
  const styles = {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  };

  const instanceFamily = instances.map(
    (instance) => instance.ec2_type.split(".")[0]
  );

  const uniqueInstanceFamily = [...new Set(instanceFamily)];

  const instanceFamilyObjects = uniqueInstanceFamily.map((instance) => ({
    ec2_type: instance,
  }));

  useEffect(() => {
    set_instance_family(instanceFamilyObjects);
  }, [instanceFamilyObjects, set_instance_family]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <div>
      <br />
      <br />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  AWS EC2 RI Marketplace Data{" "}
                  <ViewInArTwoToneIcon color="warning" />
                </Typography>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Use this tool to explore and compare AWS EC2 instance family
                  marketplace data. Select a region, instance family, and
                  operating system to view the pricing details.
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      component={Link}
                      target="_blank"
                      href="https://aws.amazon.com/blogs/aws/choosing-the-right-ec2-instance-type-for-your-application/"
                    >
                      EC2 Instance Type Selection
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      component={Link}
                      target="_blank"
                      href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ri-market-general.html"
                    >
                      What is the AWS RI Marketplace
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <form onSubmit={fetchMarket}>
                  <Autocomplete
                    onChange={(event, value) => onChangeHandle(value)}
                    id="region"
                    freeSolo
                    options={regions.map((option) => option.region)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="region"
                        color="secondary"
                        focused
                        type="text"
                        placeholder="region"
                        name="region"
                        variant="filled"
                      />
                    )}
                  />
                  <Autocomplete
                    id="ec2_type"
                    freeSolo
                    options={instance_family.map((option) => option.ec2_type)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ec2_family"
                        color="secondary"
                        focused
                        type="text"
                        placeholder="ec2 family"
                        name="ec2_type"
                        variant="filled"
                      />
                    )}
                  />
                  <Autocomplete
                    id="os_type"
                    freeSolo={false}
                    popupIcon={""}
                    options={os_type.map((option) => option.os)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="os_type"
                        color="secondary"
                        focused
                        type="text"
                        placeholder="os type"
                        name="os_type"
                        variant="filled"
                      />
                    )}
                  />
                  <br />
                  <Button type="submit" variant="outlined" color="secondary">
                    Submit
                  </Button>
                  {marketLoading && (
                    <CircularProgress color="success" size={68} />
                  )}
                </form>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              fullScreen={fullScreen}
              maxWidth="xxl"
            >
              <Card>
                <CardHeader
                  title="Marketplace Data"
                  action={
                    <Tooltip
                      title="The savings percentage displayed takes into account the upfront price in the marketplace, which is spread out over the remaining months of the contract"
                      sx={{
                        color: "primary.main",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  }
                />
                <DialogContent>
                  {marketLoading && <CircularProgress />}{" "}
                  {/* Show spinner while loading */}
                  {marketplace && (
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: 440, overflow: "auto" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Count</StyledTableCell>
                            <StyledTableCell>Duration</StyledTableCell>
                            <StyledTableCell>Instance Type</StyledTableCell>
                            <StyledTableCell>OS</StyledTableCell>
                            <StyledTableCell>Payment Option</StyledTableCell>
                            <StyledTableCell>Rate</StyledTableCell>
                            <StyledTableCell>Region</StyledTableCell>
                            <StyledTableCell>Upfront Price</StyledTableCell>
                            <StyledTableCell>On Demand Price</StyledTableCell>
                            <StyledTableCell>
                              Savings Percentage
                            </StyledTableCell>
                            <StyledTableCell>Normalized Units</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {marketplace && marketplace.market
                            ? marketplace.market
                                .sort(
                                  (a, b) =>
                                    b.adjusted_savings_percentage -
                                    a.adjusted_savings_percentage
                                ) // sort the array by adjusted_savings_percentage descending
                                .map((row) => (
                                  <TableRow key={row.id}>
                                    <StyledTableCell>
                                      {row.count}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.duration
                                        ? `${(
                                            Math.round(
                                              parseFloat(row.duration) * 10
                                            ) / 10
                                          ).toFixed(1)} months`
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row["instance type"]}
                                    </StyledTableCell>
                                    <StyledTableCell>{row.os}</StyledTableCell>
                                    <StyledTableCell>
                                      {row["payment option"]}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.rate
                                        ? `$${row.rate.toFixed(5)}`
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.region}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row["upfront price"]
                                        ? `$${row["upfront price"].toFixed(2)}`
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row["od_rate"]
                                        ? `$${row["od_rate"].toFixed(2)}`
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.adjusted_savings_percentage
                                        ? `${row.adjusted_savings_percentage.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}%`
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {
                                        normalizationFactors[
                                          row["instance type"].split(".")[1]
                                        ]
                                      }
                                    </StyledTableCell>
                                  </TableRow>
                                ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => exportCSV(marketplace.market)}
                    color="secondary"
                  >
                    Export CSV
                  </Button>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Card>
            </Dialog>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
}

export default EC2MarketPlace;
