import React, { useEffect, useState } from "react";
// import NavBar from "./NavBar";
// import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { useTheme } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import ViewInArTwoToneIcon from "@mui/icons-material/ViewInArTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CardHeader from "@mui/material/CardHeader";

const regions = [
  { region: "us-east-1" },
  { region: "us-east-2" },
  { region: "us-west-1" },
  { region: "us-west-2" },
  { region: "af-south-1" },
  { region: "ap-east-1" },
  { region: "ap-south-1" },
  { region: "ap-northeast-3" },
  { region: "ap-northeast-2" },
  { region: "ap-southeast-1" },
  { region: "ap-southeast-2" },
  { region: "ap-northeast-1" },
  { region: "ca-central-1" },
  // { region: 'cn-north-1' },
  // { region: 'cn-northwest-1' },
  { region: "eu-central-1" },
  { region: "eu-west-1" },
  { region: "eu-west-2" },
  { region: "eu-south-1" },
  { region: "eu-west-3" },
  { region: "eu-north-1" },
  { region: "me-south-1" },
  { region: "sa-east-1" },
];

const os_type = [{ os: "linux" }, { os: "windows" }];

const vCPU_count = "";

function CheapestVcpuSpot() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [marketplace, set_marketplace] = useState({});
  const [marketLoading, setMarketLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  async function fetchMarket(e) {
    e.preventDefault();
    const region = e.target.elements.region.value;
    const vCPU_count = e.target.elements.vCPU_count.value;
    const os_type = e.target.elements.os_type.value;
    setMarketLoading(true);
    // log the values
    console.log(region, vCPU_count, os_type);
    const apiData = await fetch(
      `https://wzstkt5ta4.execute-api.us-east-1.amazonaws.com/dev-v2/cheapest-vcpu/${region}/${vCPU_count}/${os_type}`
    ).then((res) => res.json());
    if (region) {
      set_marketplace({
        market: apiData,
      });
      setOpen(true);
      console.log(apiData);
      setMarketLoading(false);
    } else {
      set_marketplace({
        error: "problem with spot fetch",
      });
    }
  }

  const styles = {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <div>
      <br />
      <br />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                Cheapest EC2 Spot Price per vCPU Core{" "}
                  <ViewInArTwoToneIcon color="warning" />
                </Typography>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  This service will provide you with the cheapest available
                  Amazon EC2 spot instance types in a specified region,
                  operating system and minimum vCPU requirements.
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="flex-end"
                ></Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <form onSubmit={fetchMarket}>
                  <Autocomplete
                    id="region"
                    freeSolo
                    options={regions.map((option) => option.region)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="region"
                        color="secondary"
                        focused
                        type="text"
                        placeholder="region"
                        name="region"
                        variant="filled"
                      />
                    )}
                  />
<TextField
  id="vCPU_count"
  label="vCPU_count"
  color="secondary"
  type="number"
  placeholder="# of cores"
  name="vCPU_count"
  variant="filled"
  InputProps={{
    inputProps: {
      min: 1, // if you want to restrict input to some minimum value, for example 1
    },
  }}
  fullWidth  // Add this to fill the width of the parent container
  focused
/>


                  <Autocomplete
                    id="os_type"
                    freeSolo={false}
                    popupIcon={""}
                    options={os_type.map((option) => option.os)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="os_type"
                        color="secondary"
                        focused
                        type="text"
                        placeholder="os type"
                        name="os_type"
                        variant="filled"
                      />
                    )}
                  />
                  <br />
                  <Button type="submit" variant="outlined" color="secondary">
                    Submit
                  </Button>
                  {marketLoading && (
                    <CircularProgress color="success" size={68} />
                  )}
                </form>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              fullScreen={fullScreen}
              maxWidth="xxl"
            >
              <Card>
                <CardHeader
                  title="vCPU Spot Pricing Data"
                  action={
                    <Tooltip
                      title="Provides the user with the cheapest available Amazon EC2 spot instance types in a specified region, taking into account the user's specified operating system and minimum vCPU requirements, helping them optimize costs."
                      sx={{
                        color: "primary.main",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  }
                />
                <DialogContent>
                  {marketLoading && <CircularProgress />}{" "}
                  {/* Show spinner while loading */}
                  {marketplace && (
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: 440, overflow: "auto" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Instance Type</StyledTableCell>
                            <StyledTableCell>Availability Zone</StyledTableCell>
                            <StyledTableCell>Spot Price</StyledTableCell>
                            <StyledTableCell>vCPU</StyledTableCell>
                            <StyledTableCell>Memory</StyledTableCell>
                            <StyledTableCell>Price per vCPU</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {marketplace && marketplace.market
                            ? marketplace.market
                                .flat() // flatten the array to get rid of the subarrays
                                .map(
                                  (
                                    row,
                                    index // add an index to use as key
                                  ) => (
                                    <TableRow key={index}>
                                      <StyledTableCell>
                                        {row.instance_type}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.AvailabilityZone}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.SpotPrice}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.vcpu}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.memory}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.price_per_vcpu}
                                      </StyledTableCell>
                                    </TableRow>
                                  )
                                )
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Card>
            </Dialog>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
}

export default CheapestVcpuSpot;
