import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PaidIcon from '@mui/icons-material/Paid';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import { useTheme } from '@emotion/react';
import CloudTwoToneIcon from '@mui/icons-material/CloudTwoTone';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import CheapestVcpuSpot from './CheapestVcpuSpot';
import SpotAnalyzer from './CheapestSpotInstanceFinder';


import SvgIcon from '@mui/material/SvgIcon';

const pages = [ 'SpotAnalyzer' ] //['EC2MarketPlace'] //, 'CheapestVcpuSpot'];

function NavBar({ darkMode, handleToggle }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
              <CloudTwoToneIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
            </Link>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              EC2
            </Typography>
  
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
  id="menu-appbar"
  anchorEl={anchorElNav}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  keepMounted
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  open={Boolean(anchorElNav)}
  onClose={handleCloseNavMenu}
  sx={{
    display: { xs: 'block', md: 'none' },
  }}
>
  {pages.map((page) => {
    let label = '';
    let icon = <StorefrontIcon />;

    switch (page) {
      // case 'EC2MarketPlace':
      //   label = 'EC2 RI Market Place GOLD';
      //   icon = <StorefrontIcon />;
      //   break;
      // case 'CheapestVcpuSpot':
      //   label = 'Cheapest Vcpu Spot';
      //   icon = <PaidIcon />;
      //   break;
      case 'SpotAnalyzer':
        label = 'Cheapest Spot Instance Finder';
        icon = <PaidIcon />;
        break;
    }

    return (
      <MenuItem key={page} onClick={handleCloseNavMenu}>
        <Button href={`/${page}`} startIcon={icon} endIcon={<FiberNewRoundedIcon color='warning'/>}>
          {label}
        </Button>
      </MenuItem>
    );
  })}
</Menu>

            </Box>
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
              <CloudTwoToneIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1}} color="inherit"/>
            </Link>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              EC2
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
  {pages.map((page) => {
    let label = '';
    let icon = <StorefrontIcon />;

    switch (page) {
      case 'EC2MarketPlace':
        label = 'EC2 RI Market Place GOLD';
        icon = <StorefrontIcon />;
        break;
      case 'CheapestVcpuSpot':
        label = 'Cheapest Vcpu Spot';
        icon = <PaidIcon />;
        break;
      case 'SpotAnalyzer':
        label = 'Cheapest Spot Instance Finder';
        icon = <PaidIcon />;
        break;
    }

    return (
      <Button
        key={page}
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: 'white'}}
        href={`/${page}`}
        startIcon={icon}
        endIcon={<FiberNewRoundedIcon color='warning'/>}
      >
        {label}
      </Button>
    );
  })}
</Box>

            <Button onClick={handleToggle}>
              {darkMode ? <LightModeIcon sx={{ color: theme.palette.primary.main }} /> : <DarkModeIcon sx={{ color: theme.palette.primary.contrastText }} />}
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
  
}
export default NavBar;