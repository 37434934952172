import React, { useState, useMemo } from 'react';
import { Button, CircularProgress, Grid, TextField, Typography, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Card, CardContent } from '@mui/material';

const regions = [
  { region: 'us-east-1', name: 'US East (N. Virginia)' },
  { region: 'us-east-2', name: 'US East (Ohio)' },
  { region: 'us-west-1', name: 'US West (N. California)' },
  { region: 'us-west-2', name: 'US West (Oregon)' },
  { region: 'af-south-1', name: 'Africa (Cape Town)' },
  { region: 'ap-east-1', name: 'Asia Pacific (Hong Kong)' },
  { region: 'ap-south-1', name: 'Asia Pacific (Mumbai)' },
  { region: 'ap-southeast-1', name: 'Asia Pacific (Singapore)' },
  { region: 'ap-southeast-2', name: 'Asia Pacific (Sydney)' },
  { region: 'ap-southeast-3', name: 'Asia Pacific (Jakarta)' },
  { region: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)' },
  { region: 'ap-northeast-2', name: 'Asia Pacific (Seoul)' },
  { region: 'ap-northeast-3', name: 'Asia Pacific (Osaka)' },
  { region: 'ca-central-1', name: 'Canada (Central)' },
  { region: 'eu-central-1', name: 'Europe (Frankfurt)' },
  { region: 'eu-west-1', name: 'Europe (Ireland)' },
  { region: 'eu-west-2', name: 'Europe (London)' },
  { region: 'eu-west-3', name: 'Europe (Paris)' },
  { region: 'eu-north-1', name: 'Europe (Stockholm)' },
  { region: 'eu-south-1', name: 'Europe (Milan)' },
  { region: 'eu-south-2', name: 'Europe (Spain)' },
  { region: 'me-south-1', name: 'Middle East (Bahrain)' },
  { region: 'me-central-1', name: 'Middle East (UAE)' },
  { region: 'sa-east-1', name: 'South America (São Paulo)' },
];

const api_gw = 'https://wzstkt5ta4.execute-api.us-east-1.amazonaws.com/dev-v2';

const retryFetch = async (fetchFunction, args, retries = 3, backoff = 300) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fetchFunction(...args);
    } catch (error) {
      if (i < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, backoff * Math.pow(2, i)));
      } else {
        throw error;
      }
    }
  }
};

const fetchInstances = async (selectedRegion) => {
  const response = await fetch(`${api_gw}/instances/${selectedRegion}/`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchSpotPricing = async (region, ec2Type, osType = 'linux') => {
  const response = await fetch(`${api_gw}/spot/${region}/${ec2Type}/${osType}/`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchVcpuMem = async (ec2Type) => {
  const response = await fetch(`${api_gw}/vcpu-mem/${ec2Type}/`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

function SpotAnalyzer() {
  const [region, setRegion] = useState('');
  const [instances, setInstances] = useState([]);
  const [results, setResults] = useState([]);
  const [cheapestInstance, setCheapestInstance] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showBurstable, setShowBurstable] = useState(true);

  const calculateCheapestInstance = async (selectedRegion) => {
    try {
      setLoading(true);
      setProgress(0);
      setError(null);

      const instancesData = await fetchInstances(selectedRegion);
      setInstances(instancesData);

      let completedRequests = 0;
      const totalRequests = instancesData.length;

      const fetchPromises = instancesData.map(async (instance) => {
        try {
          const spotPricingData = await retryFetch(fetchSpotPricing, [selectedRegion, instance.ec2_type]);
          const vcpuMemData = await retryFetch(fetchVcpuMem, [instance.ec2_type]);
          completedRequests += 1;
          setProgress((completedRequests / totalRequests) * 100);
          const newResult = {
            instance: instance.ec2_type,
            spotRate: parseFloat(spotPricingData.rate),
            vcpu: vcpuMemData.vcpu,
            pricePerVcpu: parseFloat(spotPricingData.rate) / vcpuMemData.vcpu,
          };

          setResults((prevResults) => {
            const updatedResults = [...prevResults, newResult].sort((a, b) => a.pricePerVcpu - b.pricePerVcpu);
            const cheapest = updatedResults[0];

            setCheapestInstance(cheapest);
            return updatedResults;
          });
        } catch (err) {
          completedRequests += 1;
          setProgress((completedRequests / totalRequests) * 100);
        }
      });

      await Promise.all(fetchPromises);
      setLoading(false);
    } catch (error) {
      setError('An error occurred while fetching data.');
      setLoading(false);
    }
  };

  const handleRegionChange = (event, value) => {
    setRegion(value);
  };

  const handleFindCheapestInstance = () => {
    if (region) {
      calculateCheapestInstance(region);
    }
  };

  const toggleBurstableInstances = () => {
    setShowBurstable((prev) => !prev);
  };

  const memoizedResults = useMemo(() => {
    return results.filter(result => showBurstable || (!result.instance.startsWith('t')));
  }, [results, showBurstable]);

  return (
    <div>
      <br />
      <br />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Spot Analyzer
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                Find the cheapest spot instance per core to help you run your cloud-native app in Kubernetes (K8s), ECS, or ASGs.
              </Typography>
              <Autocomplete
                onChange={handleRegionChange}
                id="region"
                freeSolo
                options={regions.map((option) => option.region)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Region"
                    color="secondary"
                    focused
                    type="text"
                    placeholder="Region"
                    name="region"
                    variant="filled"
                  />
                )}
              />
              <br />
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleFindCheapestInstance}
                disabled={loading}
              >
                Find Cheapest Instance
              </Button>
              {loading && (
                <>
                  <LinearProgress variant="determinate" value={progress} />
                  <CircularProgress color="success" size={68} />
                </>
              )}
              {cheapestInstance && (
                <Typography variant="body1" component="div" gutterBottom>
                  The cheapest instance is: {cheapestInstance.instance} with a cost per vCPU of ${cheapestInstance.pricePerVcpu.toFixed(5)}
                </Typography>
              )}
              {error && (
                <Typography variant="body1" component="div" color="error" gutterBottom>
                  {error}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={10}>
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleBurstableInstances}
            style={{ marginBottom: '10px' }}
          >
            {showBurstable ? 'Hide' : 'Show'} Burstable Instances
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Instance Type</TableCell>
                  <TableCell>Spot Rate</TableCell>
                  <TableCell>vCPU</TableCell>
                  <TableCell>Price per vCPU</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {memoizedResults.map((result, index) => (
                  <TableRow key={index}>
                    <TableCell>{result.instance}</TableCell>
                    <TableCell>${result.spotRate.toFixed(5)}</TableCell>
                    <TableCell>{result.vcpu}</TableCell>
                    <TableCell>${result.pricePerVcpu.toFixed(5)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

export default SpotAnalyzer;
