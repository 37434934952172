import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

const Scores = ({ stats }) => {
  const [chipComponents, setChipComponents] = useState([]);

  useEffect(() => {
    if (stats?.scores) {
      const chipData = stats.scores.map(scoreObj => {
        const score = Math.round(scoreObj.Score);
        return {
          score: score > 100 ? 100 : score,
          zoneId: scoreObj.ZoneId,
          zoneName: scoreObj.ZoneName,
        };
      });

      const chips = chipData.map((data, index) => (
        <Box key={index} sx={{ m: 1 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Chip
              label={data.score}
              style={{
                backgroundColor: `rgb(${255 - data.score * 2.55},${data.score * 2.55},0)`,
              }}
            />
            <Typography variant="caption" component="div" color="primary">{data.zoneId}</Typography>
          </Box>
        </Box>
      ));
      setChipComponents(chips);
    }
  }, [stats]);

  const card = (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        title={<Typography variant="h5" component="div" color="primary">Availability Zone Spot Savings</Typography>}
        action={
          <Tooltip 
          title="This heatmap is comparing AWS Spot and On-Demand instances in each availability zone based on savings percentage"
          sx={{
            color: 'primary.main'
          }}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        }
      />
      <CardContent sx={{ pt: 0, pb: 0 }}>
        <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
          {chipComponents}
        </Box>
      </CardContent>
    </Card>
  );

  return card;
};

export default Scores;
