import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { purple, blue, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: blue,
  },
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const SpJsonDataDisplay = ({sp_data, od_rate}) => {

    try{
    const sorted = sp_data.sort((a, b) => a.rate - b.rate)
    
 
    return(
        <div className='App'>
        <Typography variant="h6" gutterBottom component="div">
        Savings Plan Offerings
        </Typography>
        <TableContainer component={Paper}>
        {/* <Table sx={{ minWidth: 650 }} aria-label="a dense table"> */}
        <Table
            size='small'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>offering type</StyledTableCell>
              <StyledTableCell>year</StyledTableCell>
              <StyledTableCell>payment option</StyledTableCell>
              <StyledTableCell>rate/hr</StyledTableCell>
              <StyledTableCell>discount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((data) => {
              return (
                <StyledTableRow>
                  <StyledTableCell 
                    style={{ width: 200 }}>{data["type"]}</StyledTableCell>
                  <StyledTableCell>{data["year"]}</StyledTableCell>
                  <StyledTableCell>{data["payment option"]}</StyledTableCell>
                  <StyledTableCell>${data["rate"]}</StyledTableCell>
                  <StyledTableCell>{Math.round(100 - ((data["rate"]/od_rate)*100))}%</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        </TableContainer>
      </div>
    );
  } catch(err) { 
      return <div></div>
  }
}

 
 export default SpJsonDataDisplay;