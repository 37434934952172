import React from 'react'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from '@emotion/react';

const Spot = ({error, rate, od_rate}) => {

    const theme = useTheme();
    const styles = {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    };

    const card = (
        <ThemeProvider theme={theme}>
        <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Typography variant="h5" component="div" color="primary">
        {rate && od_rate &&<p>EC2 Spot price per hour ${rate} | {Math.round(100-(rate / od_rate) * 100)}% discount</p>}
        </Typography>
        <Typography variant="h5" gutterBottom component="div">
        {error && <p>{error}</p>}
        </Typography>
        </CardContent>
        </Card>
        </ThemeProvider>
    )
    return (
        <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">{card}</Card>
        </Box>
    )
}

export default Spot;