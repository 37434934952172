import React from 'react'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import { ThemeProvider, useTheme } from '@emotion/react';

const Od = ({od_rate}) => {

    const theme = useTheme();
    const styles = {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    };

    const card = (
        <ThemeProvider theme={theme}>
        <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Typography variant="h5" component="div" color="primary">
        {od_rate && <p>EC2 On Demand price per hour ${od_rate}</p>}
        </Typography>
        </CardContent>
        </Card>
        </ThemeProvider>
        )
        return (
            <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">{card}</Card>
            </Box>
        )
}

export default Od;