import * as React from 'react';
import ReactDOM from 'react-dom/client';
// import { StyledEngineProvider } from '@mui/material/styles';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';

ReactDOM.createRoot(document.querySelector("#root")).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
