import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import { ThemeProvider, useTheme } from '@emotion/react';

const VcpuMem = ({ stats }) => {
  const theme = useTheme();
  const styles = {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  };

  // console.log('VcpuMem: stats', stats);

  const memoryInGiB = (stats?.array?.mem || 0) / 1024;

  const card = (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div" color="primary">
          EC2 Instance Stats
        </Typography>
        <Box mt={2}>
          <Typography variant="h5" component="div" color="primary">
            vCPU: {stats?.array?.vcpu || 'N/A'}
          </Typography>
          <Typography variant="h5" component="div" color="primary">
            Memory: {memoryInGiB.toFixed(2)} GiB
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  return card;
};

export default VcpuMem;
